﻿var jquery = require('jquery');
global.jQuery = global.$ = jquery;      // To get the jQuery object in the global scope because Bootstrap 4 doesn't do modules properly
var bootstrap = require('bootstrap');
var fancybox = require('@fancyapps/fancybox');
var topNavigation = require('./components/topNavigation');
var popups = require('./components/popups');
var alerts = require('./components/alerts');

$(function () {
    topNavigation.initialize();
    alerts.initialize();
    popups.initializeGallery();
})