﻿'use strict'

var jquery = require('jquery');
global.$ = global.jQuery = jquery;      // To get the jQuery object in the global scope because Bootstrap 4 doesn't do modules properly
require('mmenu-js');

module.exports = (function () {

    // private methods


    // public methods

    /**
    * @ngdoc method
    * @name initializeNavigation
    * @description Setup the navigation for the site.  Event handlers, etc
    **/
    var initializeMobileNavigation = function () {

        //console.log("topNavigation initialized");
        const menu = new Mmenu("#cu1-mobileMenu", {
          
            navbar: {
                add: true
            },
            "navbars": [
                {
                    "position": "top",
                    "class": "close-navbar",
                    "content": ['close','<span class="mm-navbar__title">Close</span>']
                },
            ],
            "offCanvas": {
                "position": "right"
            }
        });

        var API = menu.API;

        $('.mm-navbar').eq(0).addClass('custom-close-navbar');
        $('.mm-navbar').eq(1).addClass('custom-search-navbar');

        var $hamburger = $(".mobile-menu-link");
        $hamburger.on("click", function () {
            if ($(this).hasClass('is-active')) {
                API.close();
            } else {
                API.open();
            }
        });

        API.bind("open:before", function () {
            setTimeout(function () {
                $hamburger.addClass("is-active");
                $hamburger.attr('aria-label', 'close mobile menu');
            }, 100);
        });
        API.bind("close:before", function () {
            setTimeout(function () {
                $hamburger.removeClass("is-active");
                $hamburger.attr('aria-label', 'open mobile menu');
            }, 100);
        });

        //window.addEventListener("scroll", function () {
        //    var element = document.getElementById("cu1-topnav");
        //    var offset = element.getBoundingClientRect();

        //    if (offset.top <= 0) {
        //        element.classList.add("sticky");
        //    } else {
        //        element.classList.remove("sticky");
        //    }
        //});

        var element = document.getElementById("cu1-topnav");
        var isSticky = false;

        window.addEventListener("scroll", function () {
            var offset = element.getBoundingClientRect();

            if (offset.top <= 0 && !isSticky) {
                element.classList.add("sticky");
                isSticky = true;
            } else if (offset.top > 0 && isSticky) {
                element.classList.remove("sticky");
                isSticky = false;
            }
        });

    };

    // Return an object exposing the public items
    return {
        initialize: initializeMobileNavigation
    };

}());